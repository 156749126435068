import React, { useState, useRef } from 'react';
import { IMeetingInfo } from '../../types';
import { Download, ChevronDown } from 'lucide-react';
import useOutsideClick from '../../hooks/useOutsideClick';
import DownloadMenu from './DownloadMenu';

interface GlobalDownloadProps {
  meetings: IMeetingInfo[];
}

const GlobalDownload: React.FC<GlobalDownloadProps> = ({ meetings }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  const toggleDropdown = () => setIsOpen(!isOpen);

  // Create a combined meeting info object that preserves original topic names
  const allMeetingInfo: IMeetingInfo = {
    attendees: meetings.flatMap(meeting =>
      meeting.attendees.filter(attendee =>
        attendee.attendeeName && attendee.attendeeEmail // Filter out invalid attendees
      ).map(attendee => ({
        ...attendee,
        topic: meeting.interestName // Add topic to each attendee
      }))
    ),
    companyId: "all",
    interestId: "all",
    interestName: "All Meetings",
    meetingId: "all-meetings",
    meetingTimeUtc: new Date().toISOString(),
    meetingStartDateTime: meetings[0]?.meetingStartDateTime || new Date().toISOString(),
    meetingEndDateTime: meetings[meetings.length - 1]?.meetingEndDateTime || new Date().toISOString(),
    totalParticipantCount: meetings.reduce((acc, m) =>
      acc + m.attendees.filter(a => a.attendeeName && a.attendeeEmail).length, 0
    ).toString()
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="bg-blue-500 text-white px-4 py-2 rounded-md flex items-center hover:bg-blue-600 transition-colors duration-200"
      >
        <Download size={20} className="mr-2" />
        Download
        <ChevronDown size={20} className="ml-2" />
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-1 w-40 text-md font-medium bg-white rounded-md shadow-lg z-10">
          <DownloadMenu
            meetingInfo={allMeetingInfo}
            onClose={() => setIsOpen(false)}
            isGlobalDownload={true}
          />
        </div>
      )}
    </div>
  );
};

export default GlobalDownload;