import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { IAttendeeInfo } from '../../types';
import { X, Download, ChevronDown } from 'lucide-react';
import useOutsideClick from '../../hooks/useOutsideClick';
import DownloadMenu from './DownloadMenu';
import { formatAttendanceTime } from '../../utils/timeFormatter';

interface AttendeeDetailsPopupProps {
  attendee: IAttendeeInfo;
  onClose: () => void;
}

interface AttendeeDetailsPopupProps {
  attendee: IAttendeeInfo;
  onClose: () => void;
}

const AttendeeDetailsPopup: React.FC<AttendeeDetailsPopupProps> = ({ attendee, onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const downloadButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setTimeout(() => setIsVisible(true), 50);
  }, []);

  useOutsideClick(popupRef, () => {
    if (isVisible) {
      handleClose();
    }
  });

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  const toggleDownloadMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDownloadMenuOpen(!isDownloadMenuOpen);
  };

  const formatDateTime = (dateString: string) => {
    return new Date(dateString).toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
  };

  // Create a mock meeting info object for the single attendee
  const singleAttendeeMeetingInfo = {
    attendees: [attendee],
    companyId: "N/A",
    interestId: "N/A",
    interestName: "Single Attendee",
    meetingId: attendee.attendeeId,
    meetingTimeUtc: attendee.attendeeJoinDateTime,
    meetingStartDateTime: attendee.attendeeJoinDateTime,
    meetingEndDateTime: attendee.attendeeLeaveDateTime,
    totalParticipantCount: "1",
  };

  const popupContent = (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-50 z-50" onClick={handleClose}></div>
      <div
        ref={popupRef}
        className={`fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-full max-w-md shadow-2xl transition-all duration-300 z-50 ${isVisible ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
          }`}
        style={{ maxHeight: '90vh', display: 'flex', flexDirection: 'column' }}
      >
        <header className="bg-indigo-600 text-white px-6 py-2 rounded-t-lg flex items-center justify-between min-h-[48px]">
          <h3 className="text-1xl font-semibold flex-1">Attendee Details</h3>
          <div className="flex items-center gap-2 flex-shrink-0">
            <div className="relative">
              <button
                ref={downloadButtonRef}
                onClick={toggleDownloadMenu}
                className="bg-white text-indigo-600 px-3 py-1 rounded-md flex items-center text-sm transition-colors duration-200 whitespace-nowrap"
              >
                <Download size={16} className="mr-1" />
                Download
                <ChevronDown size={16} className="ml-1" />
              </button>
              {isDownloadMenuOpen && (
                <div className="absolute right-0 mt-1 z-50">
                  <DownloadMenu
                    meetingInfo={singleAttendeeMeetingInfo}
                    onClose={() => setIsDownloadMenuOpen(false)}
                  />
                </div>
              )}
            </div>
            <button
              onClick={handleClose}
              className="text-white/80 hover:text-white transition-colors duration-200 p-1"
            >
              <X size={24} />
            </button>
          </div>
        </header>

        <div className="flex-grow overflow-y-auto px-6 py-4">
          <div className="space-y-4">
            <DetailItem icon="👤" label="Name" value={attendee.attendeeName} />
            <DetailItem icon="✉️" label="Email" value={attendee.attendeeEmail} />
            <DetailItem
              icon={attendee.didAttended ? "✅" : "❌"}
              label="Attended"
              value={attendee.didAttended ? "Yes" : "No"}
            />
            <DetailItem
              icon={attendee.didAccepted ? "✅" : "❌"}
              label="Accepted"
              value={attendee.didAccepted ? "Yes" : "No"}
            />
            <DetailItem
              icon="🕒"
              label="Join Time"
              value={attendee.didAttended ? formatDateTime(attendee.attendeeJoinDateTime) : "N/A"}
            />
            <DetailItem
              icon="🏁"
              label="Leave Time"
              value={attendee.didAttended ? formatDateTime(attendee.attendeeLeaveDateTime) : "N/A"}
            />
            <DetailItem
              icon="⏱️"
              label="Total Attendance"
              value={attendee.didAttended ? formatAttendanceTime(attendee.totalAttendanceInSeconds) : "0 minutes 0 seconds"}
            />
          </div>
        </div>

        <footer className="bg-gray-100 px-6 py-4 rounded-b-lg flex justify-end">
          <button
            onClick={handleClose}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200 flex items-center"
          >
            <X size={20} className="mr-2" />
            Close
          </button>
        </footer>
      </div>
    </>
  );

  return createPortal(popupContent, document.body);
};

const DetailItem: React.FC<{ icon: string; label: string; value: string }> = ({ icon, label, value }) => (
  <div className="flex items-center">
    <span className="text-2xl mr-3">{icon}</span>
    <div className="flex-grow">
      <span className="text-sm font-medium text-gray-500">{label}</span>
      <p className="text-base text-gray-800">{value}</p>
    </div>
  </div>
);

export default AttendeeDetailsPopup;