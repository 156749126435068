import React, { useState, useRef, useId } from 'react';
import { MoreVertical, Clock, Users, Calendar, Pointer, UserCheck, ChevronDown } from 'lucide-react';
import { IMeetingInfo } from '../../types';
import AvatarGroupItem from './AvatarGroupItem';
import AvatarGroupPopover from './AvatarGroupPopover';
import DownloadMenu from './DownloadMenu';
import useOutsideClick from '../../hooks/useOutsideClick';

interface AvatarGroupProps {
  meetingInfo: IMeetingInfo;
  colorIndex: number;
}

type StatusType = 'total' | 'attended' | 'missed' | 'accepted' | null;

const AvatarGroup: React.FC<AvatarGroupProps> = ({ meetingInfo, colorIndex }) => {
  const uniqueId = useId();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAvatars, setShowAvatars] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<StatusType>(null);
  const cardRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const avatarSectionRef = useRef<HTMLDivElement>(null);

  useOutsideClick(cardRef, () => {
    if (isMenuOpen) setIsMenuOpen(false);
  });

  const togglePopover = (status: 'total' | 'attended') => (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsPopoverOpen(!isPopoverOpen);
    setSelectedStatus(status);
  };

  const toggleMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setIsPopoverOpen(false);
    setSelectedStatus(null);
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
  };

  const formatTime = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  const getStatusTitle = () => {
    switch (selectedStatus) {
      case 'total':
        return 'Total Participants';
      case 'attended':
        return 'Attended Participants';
      case 'missed':
        return 'Missed Participants';
      case 'accepted':
        return 'Accepted Participants';
      default:
        return 'All Participants';
    }
  };

  const getStatusIndicatorColor = (attendee: any) => {
    if (selectedStatus === 'accepted') {
      return 'bg-gradient-to-br from-amber-300 to-amber-400';
    }
    else if (selectedStatus === 'total') {
      return 'bg-gradient-to-br from-blue-300 to-blue-400';
    }
    return attendee.didAttended
      ? 'bg-gradient-to-br from-green-300 to-green-400'
      : 'bg-gradient-to-br from-red-300 to-red-400';
  };

  const stats = {
    total: parseInt(meetingInfo.totalParticipantCount),
    attended: meetingInfo.attendees.filter(a => a.didAttended).length,
    notAttended: meetingInfo.attendees.filter(a => !a.didAttended).length,
    accepted: meetingInfo.attendees.filter(a => a.didAccepted).length
  };

  //const attendanceRate = stats.total > 0 ? (stats.attended / stats.total) * 100 : 0;

  const toggleAvatars = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    const section = avatarSectionRef.current;
    if (section) {
      if (!showAvatars) {
        section.style.maxHeight = '0px';
        section.style.opacity = '0';
        requestAnimationFrame(() => {
          section.style.maxHeight = '48px';
          section.style.opacity = '1';
        });
      } else {
        section.style.maxHeight = '0px';
        section.style.opacity = '0';
      }
    }
    setShowAvatars(!showAvatars);
  };

  return (
    <div className="relative group">
      <div
        ref={cardRef}
        className="bg-white rounded-lg border border-gray-400 shadow-sm hover:border-gray-300 hover:shadow-md transition-all duration-300 overflow-hidden flex flex-col h-full"
      >
        <div className="relative flex flex-1">
          {/* Progress Bar */}
          <div className="absolute inset-y-0 left-0 w-1">
            <div className="absolute inset-0 bg-gray-100">
              <div
                className="relative bottom-0 top-0 left-0 right-0 bg-blue-500"
                style={{
                  height: `100%`,
                  //height: `${attendanceRate}%`,
                  //backgroundImage: 'linear-gradient(to top, rgb(59 130 246), rgb(37 99 235))'
                }}
              />
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 pl-3 pr-3 pt-3 pb-3">
            <div className="flex justify-between items-start">
              <div className="flex-grow pr-2">
                <h2 className="text-xs font-bold text-gray-800 mb-1 line-clamp-1">
                  {meetingInfo.interestName}
                </h2>
                <div className="space-y-0.5">
                  <p className="text-xs flex items-center text-gray-600">
                    <Calendar size={12} className="mr-1 flex-shrink-0" />
                    {formatDate(meetingInfo.meetingStartDateTime)}
                  </p>
                  <p className="text-xs flex items-center text-gray-600 whitespace-nowrap">
                    <Clock size={12} className="mr-1 flex-shrink-0" />
                    {formatTime(meetingInfo.meetingStartDateTime)} - {formatTime(meetingInfo.meetingEndDateTime)}
                  </p>
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={togglePopover('total')}
                      className="text-xs flex items-center bg-blue-50 text-blue-600 px-2 py-1 rounded hover:bg-blue-100 transition-colors"
                    >
                      <Users size={12} className="mr-1" />
                      {stats.total}
                    </button>
                    <button
                      onClick={togglePopover('attended')}
                      className="text-xs flex items-center bg-green-50 text-green-600 px-2 py-1 rounded hover:bg-green-100 transition-colors"
                    >
                      <UserCheck size={12} className="mr-1" />
                      {stats.attended}
                    </button>
                  </div>
                </div>
              </div>

              <div className="relative">
                <button
                  ref={menuButtonRef}
                  className="p-1 hover:bg-gray-100 rounded-full transition-colors duration-200 text-gray-500"
                  onClick={toggleMenu}
                  aria-label="More options"
                >
                  <MoreVertical size={16} />
                </button>
                {isMenuOpen && (
                  <div className="absolute right-0 mt-1 z-30">
                    <DownloadMenu
                      meetingInfo={meetingInfo}
                      onClose={() => setIsMenuOpen(false)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* View Attendees Footer Section */}
        <div className="border-t border-gray-100 bg-gray-50">
          <div className="px-3 py-2">
            <button
              onClick={toggleAvatars}
              className="w-full text-left text-sm text-indigo-600 hover:text-indigo-700 transition-colors flex items-center justify-between"
              aria-expanded={showAvatars}
              aria-controls={`avatar-section-${uniqueId}`}
            >
              <span>View Attendees</span>
              <ChevronDown
                size={16}
                className={`transform transition-transform duration-200 ${showAvatars ? 'rotate-180' : ''}`}
              />
            </button>

            <div
              ref={avatarSectionRef}
              id={`avatar-section-${uniqueId}`}
              style={{
                maxHeight: '0px',
                opacity: '0',
                overflow: 'hidden',
                transition: 'max-height 300ms ease-in-out, opacity 300ms ease-in-out'
              }}
            >
              <div className={`flex items-center space-x-1 ${showAvatars ? 'pt-2 pb-1 px-1' : ''}`}>
                {meetingInfo.attendees.slice(0, 3).map((attendee, index) => (
                  <AvatarGroupItem
                    key={`${uniqueId}-${attendee.attendeeId}`}
                    attendee={attendee}
                    index={index}
                  />
                ))}
                {meetingInfo.attendees.length > 3 && (
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      togglePopover('total')(e);
                    }}
                    className="w-6 h-6 rounded-full bg-gray-100 text-[10px] flex items-center justify-center text-gray-600 hover:bg-gray-200 transition-all duration-200"
                  >
                    +{meetingInfo.attendees.length - 3}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isPopoverOpen && (
        <AvatarGroupPopover
          attendees={meetingInfo.attendees}
          onClose={handleClose}
          companyId={meetingInfo.companyId}
          interestId={meetingInfo.interestId}
          interestName={`${meetingInfo.interestName} - ${getStatusTitle()}`}
          stats={stats}
          initialFilter={selectedStatus === 'attended' ? 'attended' : 'total'}
        />
      )}
    </div>
  );
};

export default AvatarGroup;