import React from 'react';

interface ChartTypeToggleProps {
  chartType: 'bar' | 'pie';
  setChartType: (type: 'bar' | 'pie') => void;
}

const ChartTypeToggle: React.FC<ChartTypeToggleProps> = ({ chartType, setChartType }) => {
  return (
    <div className="flex space-x-2 bg-gray-100 p-1 rounded-lg">
      <button
        onClick={() => setChartType('bar')}
        className={`flex items-center px-4 py-2 rounded-md transition-colors ${
          chartType === 'bar'
            ? 'bg-white text-blue-600 shadow'
            : 'text-gray-600 hover:text-gray-900'
        }`}
      >
        Bar Chart
      </button>
      <button
        onClick={() => setChartType('pie')}
        className={`flex items-center px-4 py-2 rounded-md transition-colors ${
          chartType === 'pie'
            ? 'bg-white text-blue-600 shadow'
            : 'text-gray-600 hover:text-gray-900'
        }`}
      >
        Pie Chart
      </button>
    </div>
  );
};

export default ChartTypeToggle;