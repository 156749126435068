import React, { useState } from 'react';
import { IAttendeeInfo } from '../../types';
import AttendeeDetailsPopup from './AttendeeDetailsPopup';

interface AvatarGroupItemProps {
  attendee: IAttendeeInfo;
  index: number;
}

const AvatarGroupItem: React.FC<AvatarGroupItemProps> = ({ attendee, index }) => {
  const [showPopup, setShowPopup] = useState(false);

  const gradients = [
    'from-pink-300 to-rose-300',
    'from-blue-300 to-indigo-300',
    'from-yellow-300 to-amber-300',
    'from-green-300 to-emerald-300',
    'from-purple-300 to-violet-300'
  ];

  const initials = attendee.attendeeName
    .split(' ')
    .map(n => n[0])
    .join('')
    .toUpperCase();

  const togglePopup = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowPopup(!showPopup);
  };

  return (
    <>
      <div className="relative group z-10">
        <button
          className={`w-6 h-6 rounded-full bg-gradient-to-br ${gradients[index % gradients.length]} text-gray-800 text-[10px] flex items-center justify-center transform transition-all duration-200 hover:scale-110 hover:shadow-lg opacity-95`}
          title={attendee.attendeeName}
          onClick={togglePopup}
        >
          {initials}
        </button>
        <div
          className={`absolute -top-0.5 -right-0.5 w-2.5 h-2.5 rounded-full border-2 border-white shadow-sm transition-transform duration-200 group-hover:scale-110 ${attendee.didAttended
              ? 'bg-gradient-to-br from-green-300 to-green-400'
              : 'bg-gradient-to-br from-red-300 to-red-400'
            }`}
        ></div>
      </div>
      {showPopup && (
        <AttendeeDetailsPopup attendee={attendee} onClose={() => setShowPopup(false)} />
      )}
    </>
  );
};

export default AvatarGroupItem;