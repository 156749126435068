import { useContext } from 'react'
import { LandingPage } from '../pages/landingPage/Index'
import { TeamsFxContext } from './Context'
import { FluentProvider, teamsLightTheme } from '@fluentui/react-components'

export default function Tab() {
  const { themeString } = useContext(TeamsFxContext)
  return (
    <FluentProvider theme={teamsLightTheme}>
      <div className={themeString === 'default' ? 'light' : themeString === 'dark' ? 'dark' : 'contrast'}>
      {<LandingPage />}
      </div>
    </FluentProvider>
  )
}
