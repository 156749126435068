import { DocumentCard } from '@fluentui/react'
import { ProgressBar } from '@fluentui/react-components'
import { AvatarGroup, AvatarGroupItem, AvatarGroupPopover, partitionAvatarGroupItems } from '@fluentui/react-components'
import moment from 'moment'
import { IMeetingInfo } from '.'
//import "./src/index.css"

export default function Meeting(props: IMeetingInfo) {
  const partitionedItems = partitionAvatarGroupItems({ items: props?.attendees ?? [] })

  return (
    <DocumentCard
      style={{
        width: '13.5rem',
        height: '8.5rem',
        padding: '0.5rem',
        borderRadius: '0.5rem',
        boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
        marginBottom: '1.5rem',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row', gap: '0.6rem' }}>
        <div>
          <ProgressBar style={{ height: '7.5rem', width: '0.4rem' }} thickness="large" value={0.8} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.3rem' }}>
          <div>
            <span className="ms-fontWeight-semibold" style={{ fontSize: '14px', color: '#242424' }}>
              {props.interestName}
            </span>
          </div>
          <div>
            <caption style={{ display: 'inline-flex' }}>
              {moment(moment(props.meetingTimeUtc)).format('HH:mm A')} -{' '}
              {moment(moment(props.meetingTimeUtc).add(15, 'minutes')).format('HH:mm A')}
            </caption>
          </div>
          <div style={{ display: 'inline-flex' }}>
            <span>TotalParticipants: </span>{props.totalParticipantCount}
          </div>

          {/* <span className="absolute bottom-0 left-8 transform translate-y-1/4 w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span> */}
          {/* <span className="absolute bottom-0 left-8 transform translate-y-1/4 w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span> */}
          <div style={{ paddingTop: '0.3rem' }}>
            <AvatarGroup layout="spread" size={24}>
              <span className="absolute bottom-0 left-8 transform translate-y-1/4 w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full"></span>
              {partitionedItems.inlineItems.map((item) => (
                <AvatarGroupItem name={item.attendeeName} key={item.attendeeName} />

              ))}

              {partitionedItems.overflowItems && (
                <AvatarGroupPopover>
                  {partitionedItems.overflowItems.map((item) => (
                    <AvatarGroupItem name={item.attendeeName} key={item.attendeeName} />

                  ))}
                  {/* <span className="greenCircleStatus"></span> */}
                  {/* {<span className="bottom-0 left-7 absolute w-3.5 h-3.5 bg-green-400 border-2 border-white dark:border-gray-800 rounded-full" >sdsad</span>} */}
                </AvatarGroupPopover>
              )}

            </AvatarGroup>

          </div>
        </div>
      </div>

      {/* <DocumentCardActivity activity="Sent March 13, 2018" people={people.slice(6)} /> */}
    </DocumentCard>
  )
}
