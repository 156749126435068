import { IMeetingInfo, IAttendeeInfo } from '../types';

const generateRandomDate = (start: Date, end: Date) => {
  return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
};

const generateAttendee = (id: string, forceNotAttended: boolean = false, forceNotAccepted: boolean = false): IAttendeeInfo => {
  const firstNames = [
    'Alice', 'Bob', 'Charlie', 'David', 'Eva', 'Frank', 'Grace', 'Henry', 'Ivy', 'Jack',
    'Katherine', 'Liam', 'Mia', 'Noah', 'Olivia', 'Peter', 'Quinn', 'Rachel', 'Samuel', 'Tara',
    'Uma', 'Victor', 'Wendy', 'Xavier', 'Yara', 'Zack', 'Anna', 'Benjamin', 'Clara', 'Daniel',
    'Emma', 'Felix', 'Georgia', 'Hugo', 'Isabel', 'James', 'Kylie', 'Lucas', 'Maya', 'Nathan'
  ];
  const lastNames = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez',
    'Anderson', 'Taylor', 'Thomas', 'Moore', 'Jackson', 'Martin', 'Lee', 'Thompson', 'White', 'Harris',
    'Clark', 'Lewis', 'Robinson', 'Walker', 'Young', 'King', 'Wright', 'Scott', 'Green', 'Baker',
    'Adams', 'Nelson', 'Carter', 'Mitchell', 'Parker', 'Collins', 'Morris', 'Murphy', 'Cook', 'Rogers'
  ];
  const name = `${firstNames[Math.floor(Math.random() * firstNames.length)]} ${lastNames[Math.floor(Math.random() * lastNames.length)]}`;
  const email = `${name.toLowerCase().replace(' ', '.')}@example.com`;
  const didAttended = forceNotAttended ? false : Math.random() > 0.2;
  const didAccepted = forceNotAccepted ? false : Math.random() > 0.1;
  const joinDateTime = generateRandomDate(new Date(2024, 0, 1), new Date(2024, 11, 31));
  const leaveDateTime = new Date(joinDateTime.getTime() + Math.random() * 3600000);
  const totalAttendanceInSeconds = Math.floor((leaveDateTime.getTime() - joinDateTime.getTime()) / 1000).toString();

  return {
    attendeeId: id,
    attendeeName: name,
    attendeeEmail: email,
    didAttended,
    didAccepted,
    attendeeJoinDateTime: joinDateTime.toISOString(),
    attendeeLeaveDateTime: leaveDateTime.toISOString(),
    totalAttendanceInSeconds,
  };
};

export const generateMeetingData = (count: number): IMeetingInfo[] => {
  const meetings: IMeetingInfo[] = [];
  const interests = [
    'Blockchain', 'Artificial Intelligence', 'Machine Learning', 'Internet of Things', 'Cloud Computing',
    'Cybersecurity', 'Data Science', 'DevOps Practices', 'Web Development', 'Mobile Development',
    'Quantum Computing', 'Augmented Reality', 'Virtual Reality', 'Edge Computing', '5G Networks',
    'Robotics', 'Natural Language Processing', 'Computer Vision', 'Big Data Analytics', 'Digital Transformation',
    'UI/UX Design', 'Microservices Architecture', 'Serverless Computing', 'Sustainable Technology', 'FinTech',
    'HealthTech', 'EdTech', 'Green Technology', 'Smart Cities', 'Autonomous Systems',
    'Blockchain DeFi', 'Cloud Native', 'API Development', 'Software Testing', 'Project Management',
    'Agile Methodologies', 'Digital Marketing', 'E-commerce Solutions', 'Network Security', 'Data Privacy'
  ];

  for (let i = 0; i < count; i++) {
    const attendeesCount = Math.floor(Math.random() * 15) + 5; // Generate between 5 and 20 attendees
    const attendees: IAttendeeInfo[] = [];

    // Every 5th meeting will have zero attended participants
    const forceNoAttendance = (i+2) % 5 === 0;
    // Every 7th meeting will have zero accepted participants
    const forceNoAcceptance = (i+2) % 7 === 0;

    for (let j = 0; j < attendeesCount; j++) {
      attendees.push(generateAttendee(`attendee-${(i+2)}-${j}`, forceNoAttendance, forceNoAcceptance));
    }

    const startDateTime = generateRandomDate(new Date(2024, 0, 1), new Date(2024, 11, 31));
    const endDateTime = new Date(startDateTime.getTime() + Math.random() * 3600000);

    meetings.push({
      attendees,
      companyId: `company${i + 1}`,
      interestId: `interest${i + 1}`,
      interestName: interests[i % interests.length],
      meetingId: `meeting${i + 1}`,
      meetingTimeUtc: startDateTime.toISOString(),
      meetingStartDateTime: startDateTime.toISOString(),
      meetingEndDateTime: endDateTime.toISOString(),
      totalParticipantCount: attendeesCount.toString(),
    });
  }

  return meetings;
};