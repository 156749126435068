import { DocumentCard } from '@fluentui/react'
import { Subtitle2Stronger } from '@fluentui/react-components'
import { AvatarGroup, AvatarGroupItem, AvatarGroupPopover, partitionAvatarGroupItems } from '@fluentui/react-components'
import moment from 'moment'

export interface AttendeeInfo {
  id: string
  name: string
  email: string
  didAccept: boolean
  didAttend: boolean
}

export interface IMeetingInfo {
  attendees: AttendeeInfo[]
  interestName: string
  atUtc: Date
  isUpComing: boolean
}

export default function Meeting(props: IMeetingInfo) {
  const getMeetingTimeString = (time: Date) => {
    return `${moment(time).format('hh:mm A')} - ${moment(time).add(15, 'm').format('hh:mm A')} `
  }

  return (
    <DocumentCard
      style={{
        width: '17rem',
        height: '8rem',
        padding: '1rem',
        backgroundColor: '#F9F9F9',
        border: '1px solid #F9F9F9',
        borderRadius: '0.5rem',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: '0.2rem' }}>
        <div>
          <Subtitle2Stronger>{props.interestName}</Subtitle2Stronger>
        </div>
        <div>
          <caption style={{ display: 'inline-flex' }}>{getMeetingTimeString(props.atUtc)}</caption>
        </div>

        {props.isUpComing && (
          <div>
            <caption style={{ color: 'red', display: 'inline-flex' }}>
              Starts in {moment(props.atUtc).fromNow()}
            </caption>
          </div>
        )}
      </div>
      {props.attendees && (
        <AvatarGroup layout="spread">
          {partitionAvatarGroupItems({ items: props.attendees }).inlineItems.map((item) => (
            <AvatarGroupItem name={item.name} key={item.name} />
          ))}
        </AvatarGroup>
      )}
    </DocumentCard>
  )
}
