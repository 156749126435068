import { Checkbox } from '@fluentui/react-components'

export interface InterestPreferenceProps {
  id: string
  name: string
  isUserSubscribed: boolean
  onChangePreference: (id: string, isUserSubscribed: boolean) => Promise<void>
}

export default function InterestPreference(props: InterestPreferenceProps) {
  return (
    <Checkbox
      className={
        props.isUserSubscribed ? 'animate__animated animate__fadeInUp' : 'animate__animated animate__fadeInDown'
      }
      checked={props.isUserSubscribed}
      label={props.name}
      onChange={(ev) => props.onChangePreference(props.id, ev.target.checked)}
      key={props.id}
    />
  )
}
