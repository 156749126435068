export const formatAttendanceTime = (seconds: number | string): string => {
  const totalSeconds = typeof seconds === 'string' ? parseInt(seconds) : seconds;
  const minutes = Math.floor(totalSeconds / 60);
  const remainingSeconds = totalSeconds % 60;
  
  if (minutes === 0) {
    return `${remainingSeconds} seconds`;
  } else if (remainingSeconds === 0) {
    return `${minutes} minutes`;
  } else {
    return `${minutes} minutes ${remainingSeconds} seconds`;
  }
};