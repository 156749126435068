import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { IAttendeeInfo } from '../../types';
import { X, Download, ChevronDown, Users, Check, UserCheck, UserX, Clock } from 'lucide-react';
import useOutsideClick from '../../hooks/useOutsideClick';
import DownloadMenu from './DownloadMenu';

interface Stats {
  total: number;
  attended: number;
  notAttended: number;
  accepted: number;
}

interface AvatarGroupPopoverProps {
  attendees: IAttendeeInfo[];
  onClose: () => void;
  companyId: string;
  interestId: string;
  interestName: string;
  stats: Stats;
  initialFilter?: 'total' | 'attended' | 'missed' | 'accepted';
  showTotalOnly?: boolean;
}

const AvatarGroupPopover: React.FC<AvatarGroupPopoverProps> = ({
  attendees,
  onClose,
  companyId,
  interestId,
  interestName,
  stats,
  initialFilter = 'total',
  showTotalOnly = false
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDownloadMenuOpen, setIsDownloadMenuOpen] = useState(false);
  const [activeFilter, setActiveFilter] = useState(initialFilter);
  const popoverRef = useRef<HTMLDivElement>(null);
  const downloadButtonRef = useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    setTimeout(() => setIsVisible(true), 50);
  }, []);

  useOutsideClick(popoverRef, () => {
    if (isVisible) {
      handleClose();
    }
  });

  const handleClose = () => {
    setIsVisible(false);
    setTimeout(onClose, 300);
  };

  const toggleDownloadMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsDownloadMenuOpen(!isDownloadMenuOpen);
  };

  const getFilteredAttendees = () => {
    switch (activeFilter) {
      case 'attended':
        return attendees.filter(a => a.didAttended);
      case 'missed':
        return attendees.filter(a => !a.didAttended);
      case 'accepted':
        return attendees.filter(a => a.didAccepted);
      default:
        return attendees;
    }
  };

  const getExportMeetingInfo = () => {
    const currentAttendees = getFilteredAttendees();
    const currentTitle = interestName;

    return {
      attendees: currentAttendees,
      companyId,
      interestId,
      interestName: currentTitle,
      meetingId: 'filtered',
      meetingTimeUtc: new Date().toISOString(),
      meetingStartDateTime: new Date().toISOString(),
      meetingEndDateTime: new Date().toISOString(),
      totalParticipantCount: currentAttendees.length.toString(),
    };
  };

  const getButtonStyles = (filter: string) => {
    const baseStyles = "flex flex-col items-center p-2 rounded-lg transition-all ";

    switch (filter) {
      case 'total':
        return baseStyles + (activeFilter === 'total'
          ? 'bg-blue-100 text-blue-700 ring-2 ring-blue-500'
          : 'bg-white text-gray-600 hover:bg-gray-100');
      case 'attended':
        return baseStyles + (activeFilter === 'attended'
          ? 'bg-green-100 text-green-700 ring-2 ring-green-500'
          : 'bg-white text-gray-600 hover:bg-gray-100');
      case 'missed':
        return baseStyles + (activeFilter === 'missed'
          ? 'bg-red-100 text-red-700 ring-2 ring-red-500'
          : 'bg-white text-gray-600 hover:bg-gray-100');
      case 'accepted':
        return baseStyles + (activeFilter === 'accepted'
          ? 'bg-amber-100 text-amber-700 ring-2 ring-amber-500'
          : 'bg-white text-gray-600 hover:bg-gray-100');
      default:
        return baseStyles + 'bg-white text-gray-600 hover:bg-gray-100';
    }
  };

  return createPortal(
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-[9998]"
        onClick={handleClose}
      ></div>
      <div
        ref={popoverRef}
        className={`fixed left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg w-full max-w-md shadow-2xl transition-all duration-300 ease-in-out z-[9999] ${isVisible ? 'scale-100 opacity-100' : 'scale-95 opacity-0'
          }`}
        style={{ maxHeight: '90vh', display: 'flex', flexDirection: 'column' }}
      >
        <header className="bg-indigo-600 text-white px-4 py-3 rounded-t-lg flex items-center justify-between min-h-[48px]">
          <h3 className="text-lg font-semibold leading-tight line-clamp-1 flex-1">{interestName}</h3>
          <div className="flex items-center gap-2 flex-shrink-0">
            <div className="relative">
              <button
                ref={downloadButtonRef}
                onClick={toggleDownloadMenu}
                className="bg-white/10 hover:bg-white/20 text-white px-2 py-1 rounded-md flex items-center text-sm transition-colors duration-200 whitespace-nowrap"
              >
                <Download size={14} className="mr-1" />
                Download
                <ChevronDown size={14} className="ml-1" />
              </button>
              {isDownloadMenuOpen && (
                <div className="absolute right-0 mt-1 z-50">
                  <DownloadMenu
                    meetingInfo={getExportMeetingInfo()}
                    onClose={() => setIsDownloadMenuOpen(false)}
                  />
                </div>
              )}
            </div>
            <button
              onClick={handleClose}
              className="text-white/80 hover:text-white transition-colors duration-200 p-1"
            >
              <X size={20} />
            </button>
          </div>
        </header>

        <div className="grid grid-cols-4 gap-2 p-4 bg-gray-50">
          <button
            onClick={() => setActiveFilter('total')}
            className={getButtonStyles('total')}
          >
            <Users size={16} className="mb-1" />
            <span className="text-xs font-medium">Total</span>
            <span className="text-lg font-bold">{stats.total}</span>
          </button>
          <button
            onClick={() => setActiveFilter('attended')}
            className={getButtonStyles('attended')}
          >
            <UserCheck size={16} className="mb-1" />
            <span className="text-xs font-medium">Attended</span>
            <span className="text-lg font-bold">{stats.attended}</span>
          </button>
          <button
            onClick={() => setActiveFilter('missed')}
            className={getButtonStyles('missed')}
          >
            <UserX size={16} className="mb-1" />
            <span className="text-xs font-medium">Missed</span>
            <span className="text-lg font-bold">{stats.notAttended}</span>
          </button>
          <button
            onClick={() => setActiveFilter('accepted')}
            className={getButtonStyles('accepted')}
          >
            <Clock size={16} className="mb-1" />
            <span className="text-xs font-medium">Accepted</span>
            <span className="text-lg font-bold">{stats.accepted}</span>
          </button>
        </div>

        <div className="flex-grow overflow-y-auto px-6 py-4">
          <div className="space-y-3">
            {getFilteredAttendees().map((attendee) => (
              <div
                key={attendee.attendeeId}
                className="bg-gray-50 p-3 rounded-lg"
              >
                <div className="flex items-center">
                  <div className="relative">
                    <div className="w-8 h-8 rounded-full bg-indigo-100 text-indigo-600 flex items-center justify-center font-semibold">
                      {attendee.attendeeName.charAt(0).toUpperCase()}
                    </div>
                    <div
                      className={`absolute -top-1 -right-1 w-3 h-3 rounded-full border-2 border-white ${attendee.didAttended
                        ? 'bg-gradient-to-br from-green-300 to-green-400'
                        : 'bg-gradient-to-br from-red-300 to-red-400'
                        }`}
                    ></div>
                  </div>
                  <div className="ml-3">
                    <span className="font-medium text-gray-800">
                      {attendee.attendeeName}
                    </span>
                    {attendee.didAccepted && (
                      <div className="text-amber-500 flex items-center text-xs mt-0.5">
                        <Check size={12} className="mr-1" />
                        Accepted Invitation
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <footer className="bg-gray-100 px-6 py-4 rounded-b-lg flex justify-end">
          <button
            onClick={handleClose}
            className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600 transition-colors duration-200 flex items-center"
          >
            <X size={20} className="mr-2" />
            Close
          </button>
        </footer>
      </div>
    </>,
    document.body
  );
};

export default AvatarGroupPopover;