import { IMeetingInfo, IAttendeeInfo } from '../types';
import { formatDateTime } from './dateFormatter';
import { formatAttendanceTime } from './timeFormatter';
import { utils } from 'xlsx-js-style';
import { excelStyles, ExcelStyleKey } from './exportStyles';
import jsPDF from 'jspdf';

interface StatsResult {
  topic: string;
  totalParticipants: number;
  attended: { value: number; style: ExcelStyleKey };
  notAttended: { value: number; style: ExcelStyleKey };
  accepted: { value: number; style: ExcelStyleKey };
  attendanceRate: { value: string; style: ExcelStyleKey };
  acceptanceRate: { value: string; style: ExcelStyleKey };
  avgAttendanceTime: string;
  startTime: string;
  endTime: string;
}

const getRateStyle = (rate: number): ExcelStyleKey => {
  if (rate >= 80) return 'highRate';
  if (rate >= 60) return 'mediumRate';
  return 'lowRate';
};

export const generateMeetingStats = (meetingInfo: IMeetingInfo): StatsResult => {
  const attendedCount = meetingInfo.attendees.filter(
    (a) => a.didAttended
  ).length;
  const notAttendedCount = meetingInfo.attendees.filter(
    (a) => !a.didAttended
  ).length;
  const acceptedCount = meetingInfo.attendees.filter(
    (a) => a.didAccepted
  ).length;
  const totalCount = meetingInfo.attendees.length;

  const startTime = new Date(meetingInfo.meetingStartDateTime).toLocaleString();
  const endTime = new Date(meetingInfo.meetingEndDateTime).toLocaleString();

  const totalAttendanceSeconds = meetingInfo.attendees
    .filter((a) => a.didAttended)
    .reduce((acc, curr) => acc + parseInt(curr.totalAttendanceInSeconds), 0);

  const avgAttendanceMinutes =
    totalAttendanceSeconds / (60 * (attendedCount || 1));
  const attendanceRate = (attendedCount / totalCount) * 100;
  const acceptanceRate = (acceptedCount / totalCount) * 100;

  return {
    topic: meetingInfo.interestName,
    totalParticipants: totalCount,
    attended: { value: attendedCount, style: 'attended' },
    notAttended: { value: notAttendedCount, style: 'notAttended' },
    accepted: { value: acceptedCount, style: 'accepted' },
    attendanceRate: {
      value: `${attendanceRate.toFixed(1)}%`,
      style: getRateStyle(attendanceRate),
    },
    acceptanceRate: {
      value: `${acceptanceRate.toFixed(1)}%`,
      style: getRateStyle(acceptanceRate),
    },
    avgAttendanceTime: `${avgAttendanceMinutes.toFixed(1)} minutes`,
    startTime,
    endTime,
  };
};

export const generateAttendeeRow = (
  attendee: IAttendeeInfo & { topic?: string },
  meetingInfo: IMeetingInfo
) => {
  return {
    topic: attendee.topic || meetingInfo.interestName,
    name: attendee.attendeeName,
    email: attendee.attendeeEmail,
    status: {
      value: attendee.didAttended ? 'Attended' : 'Did Not Attend',
      style: attendee.didAttended ? 'attended' : 'notAttended' as ExcelStyleKey,
    },
    accepted: {
      value: attendee.didAccepted ? 'Yes' : 'No',
      style: attendee.didAccepted ? 'accepted' : 'notAccepted' as ExcelStyleKey,
    },
    joinTime: attendee.didAttended
      ? formatDateTime(attendee.attendeeJoinDateTime, true)
      : 'N/A',
    leaveTime: attendee.didAttended
      ? formatDateTime(attendee.attendeeLeaveDateTime, true)
      : 'N/A',
    duration: attendee.didAttended
      ? formatAttendanceTime(parseInt(attendee.totalAttendanceInSeconds))
      : '0 minutes 0 seconds',
    joinDate: attendee.didAttended
      ? formatDateTime(attendee.attendeeJoinDateTime, false)
      : 'N/A',
    leaveDate: attendee.didAttended
      ? formatDateTime(attendee.attendeeLeaveDateTime, false)
      : 'N/A',
  };
};

export const applyExcelStyles = (ws: any) => {
  const range = utils.decode_range(ws['!ref'] || 'A1:J100');

  // Set column widths
  const colWidths = [
    { wch: 15 }, // Topic
    { wch: 20 }, // Name
    { wch: 30 }, // Email
    { wch: 15 }, // Status
    { wch: 12 }, // Accepted
    { wch: 25 }, // Join Time
    { wch: 25 }, // Leave Time
    { wch: 20 }, // Duration
    { wch: 15 }, // Join Date
    { wch: 15 }, // Leave Date
  ];
  ws['!cols'] = colWidths;

  // Set row height
  const rowHeights = Array(range.e.r + 1).fill({ hpt: 25 }); // 25 points height
  ws['!rows'] = rowHeights;

  for (let R = range.s.r; R <= range.e.r; ++R) {
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_address = utils.encode_cell({ r: R, c: C });
      const cell = ws[cell_address];

      if (!cell) continue;

      // Base style for all cells
      const baseStyle = {
        alignment: {
          vertical: 'center',
          wrapText: true,
        },
        border: {
          top: { style: 'thin', color: { rgb: '000000' } },
          bottom: { style: 'thin', color: { rgb: '000000' } },
          left: { style: 'thin', color: { rgb: '000000' } },
          right: { style: 'thin', color: { rgb: '000000' } },
        },
      };

      if (R === 0) {
        // Header style
        cell.s = {
          ...baseStyle,
          fill: { fgColor: { rgb: '4F46E5' }, patternType: 'solid' },
          font: { color: { rgb: 'FFFFFF' }, bold: true, size: 11 },
          alignment: { ...baseStyle.alignment, horizontal: 'center' },
        };
      } else {
        // Data cell style
        const cellStyle = cell.s || {};
        cell.s = {
          ...baseStyle,
          ...cellStyle,
          font: { ...cellStyle.font, size: 10 },
        };
      }
    }
  }
};