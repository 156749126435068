import { Plugin, Chart } from 'chart.js';

type ChartJSPieChart = Chart<'pie', number[], string>;

export const externalLabelsPlugin: Plugin<'pie'> = {
  id: 'externalLabels',
  afterDraw: (chart: ChartJSPieChart) => {
    const {
      ctx,
      chartArea: { top, bottom, left, right, width, height },
    } = chart;
    ctx.save();

    const centerX = (left + right) / 2;
    const centerY = (top + bottom) / 2;
    const radius = Math.min(width, height) * 0.35;

    if (chart.data.labels && Array.isArray(chart.data.labels)) {
      chart.data.labels.forEach((label, i) => {
        const meta = chart.getDatasetMeta(0);
        const arc = meta.data[i];
        if (!arc) return;

        const arcElement = arc as unknown as {
          startAngle: number;
          endAngle: number;
        };

        const angle =
          arcElement.startAngle + (arcElement.endAngle - arcElement.startAngle) / 2;
        const labelRadius = radius * 1.5;
        const x = centerX + Math.cos(angle) * labelRadius;
        const y = centerY + Math.sin(angle) * labelRadius;

        ctx.beginPath();
        ctx.moveTo(
          centerX + Math.cos(angle) * radius,
          centerY + Math.sin(angle) * radius
        );
        ctx.lineTo(x, y);

        const lineLength = 15;
        const xDirection = Math.cos(angle) > 0 ? 1 : -1;
        ctx.lineTo(x + lineLength * xDirection, y);

        const dataset = chart.data.datasets[0];
        const backgroundColor = Array.isArray(dataset.backgroundColor)
          ? dataset.backgroundColor[i]
          : dataset.backgroundColor;

        ctx.strokeStyle = backgroundColor?.toString() || '#000';
        ctx.lineWidth = 1.5;
        ctx.stroke();

        ctx.font = 'bold 14px Arial';
        ctx.fillStyle = backgroundColor?.toString() || '#000';
        ctx.textAlign = Math.cos(angle) > 0 ? 'left' : 'right';
        ctx.textBaseline = 'middle';
        ctx.fillText(label.toString(), x + 20 * xDirection, y);
      });
    }

    ctx.restore();
  },
};