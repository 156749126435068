import { useAppStateStore } from '../../store'
import { apiPost } from '../../services/fetch/apiClient'
import { Slider, IStackTokens, Stack, IStackStyles } from '@fluentui/react'
import { logger } from '../../components/App'

export interface IMeetingAttendeesInfo {
  minAttendees: number
  maxAttendees: number
}

const stackStyles: Partial<IStackStyles> = { root: { maxWidth: 300 } }
const stackTokens: IStackTokens = { childrenGap: 20 }

export default function MeetingAttendees(props: IMeetingAttendeesInfo) {
  const onChange = (event: any, value: number, range?: [number, number]) => {
    if (range) {
      const min = range[0]
      const max = range[1]
      updateMeetingAttendees(min, max)
    }
  }

  const appState = useAppStateStore()

  const updateMeetingAttendees = async (minAttendees: number, maxAttendees: number) => {
    try {
      await apiPost('company/meetingsettings', appState.identity.accessToken, {
        minAttendees: minAttendees,
        maxAttendees: maxAttendees,
      })

      appState.setMeetingAttendeeCount(minAttendees, maxAttendees)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  return (
    <>
      <Stack tokens={stackTokens} styles={stackStyles}>
        <Slider
          ranged
          min={2}
          max={8}
          defaultLowerValue={props.minAttendees}
          defaultValue={props.maxAttendees}
          onChanged={onChange}
        />
      </Stack>
    </>
  )
}
