export interface ProfileInfo {
  displayName: string
  givenName: string
  mail: string
  surname: string
  userPrincipalName: string
  id: string
}

export const getProfilePic = async (accessToken: string, userId: string, size?: 48 | 64 | 96 | 120): Promise<any> => {
  const res = await fetch(`https://graph.microsoft.com/v1.0/users/${userId}/photos/${size}x${size}/$value/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      responseType: 'blob',
    },
  })

  var result = await res.blob()
  return result
}
