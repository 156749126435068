import { Field, Radio, RadioGroup } from '@fluentui/react-components'
import { useAppStateStore } from '../../store'
import { apiPost } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'

export default function MeetingFrequency() {
  const appState = useAppStateStore()

  const onChange = async (event: any) => {
    const nextMeetingInDays = parseInt(event.target.value)
    try {
      await apiPost('settings/set-meeting-frequency', appState.identity.accessToken, {
        nextMeetingTimespanInDays: nextMeetingInDays,
      })

      appState.setMeetingFrequency(nextMeetingInDays)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  return (
    <div>
      <Field>
        <RadioGroup
          defaultValue={appState.profile.meetingFrequency.toString()}
          style={{ display: 'flex', flexDirection: 'row' }}
          onChange={onChange}
        >
          <Radio value="1" label="Daily" />
          <Radio value="2" label="Alternate Days" />
          <Radio value="7" label="Weekly" />
        </RadioGroup>
      </Field>
    </div>
  )
}
