import React, { useState } from 'react';
import Select from 'react-select';
import { XCircle, Calendar, Users, FilterIcon } from 'lucide-react';

interface TopicOption {
  value: string;
  label: string;
  color: {
    bg: string;
    text: string;
    chart: string;
  };
}

interface AttendeeOption {
  value: string;
  label: string;
  email: string;
  topic: string;
  color: {
    bg: string;
    text: string;
    chart: string;
  };
}

interface DateRange {
  start: string;
  end: string;
}

interface SharedFiltersProps {
  topicOptions: TopicOption[];
  selectedTopics: TopicOption[];
  setSelectedTopics: (topics: TopicOption[]) => void;
  attendeeOptions: any[];
  selectedAttendees: AttendeeOption[];
  setSelectedAttendees: (attendees: AttendeeOption[]) => void;
  dateRange: DateRange;
  setDateRange: (range: DateRange) => void;
  clearAllFilters: () => void;
  hasActiveFilters: boolean;
}

interface DateRangeOption {
  value: string;
  label: string;
}

const dateRangeOptions: DateRangeOption[] = [
  { value: '30d', label: 'Last 30 Days' },
  { value: '60d', label: 'Last 60 Days' },
  { value: '90d', label: 'Last 90 Days' },
  { value: 'custom', label: 'Custom Range' }
];

const SharedFilters: React.FC<SharedFiltersProps> = ({
  topicOptions,
  selectedTopics,
  setSelectedTopics,
  attendeeOptions,
  selectedAttendees,
  setSelectedAttendees,
  dateRange,
  setDateRange,
  clearAllFilters,
  hasActiveFilters
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeOption | null>(null);
  const [showCustomDateRange, setShowCustomDateRange] = useState(false);

  const uniqueUsers = Array.from(
    new Set(
      attendeeOptions.flatMap((group: any) =>
        group.options.map((option: any) => ({
          value: option.value,
          label: option.label.split(' (')[0],
          email: option.email,
          color: option.color
        }))
      )
    )
  );

  const handleDateRangeChange = (selected: DateRangeOption | null) => {
    setSelectedDateRange(selected);
    setShowCustomDateRange(selected?.value === 'custom');

    if (!selected) {
      setDateRange({ start: '', end: '' });
      return;
    }

    const now = new Date();
    let start = new Date();

    switch (selected.value) {
      case '30d':
        start.setDate(now.getDate() - 30);
        break;
      case '60d':
        start.setDate(now.getDate() - 60);
        break;
      case '90d':
        start.setDate(now.getDate() - 90);
        break;
      case 'custom':
        return;
      default:
        start = new Date(0);
    }

    setDateRange({
      start: start.toISOString().split('T')[0],
      end: now.toISOString().split('T')[0]
    });
  };

  const getControlHeight = (selected: any[]) => {
    if (!selected || selected.length === 0) return '32px';
    if (selected.length <= 2) return '32px';
    return '48px';
  };

  const getHoverStyles = (color: any) => {
    if (!color?.bg) return {
      background: '#FEF9C3',
      color: '#854D0E',
      boxShadow: '0 0 0 1px rgba(133, 77, 14, 0.1)'
    };

    const baseColor = color.bg.slice(0, -2);
    return {
      background: `${baseColor}15`,
      color: color.text,
      boxShadow: `0 0 0 1px ${baseColor}10`
    };
  };

  const customStyles = {
    control: (base: any, state: any) => ({
      ...base,
      minHeight: '32px',
      maxHeight: getControlHeight(state.getValue()),
      background: 'white',
      borderColor: '#E5E7EB',
      borderWidth: '1px',
      borderRadius: '0.5rem',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
      transition: 'all 0.2s ease',
      '&:hover': {
        borderColor: '#A5B4FC',
        boxShadow: '0 0 0 1px rgba(165, 180, 252, 0.5)',
      },
      '&:focus-within': {
        borderColor: '#818CF8',
        boxShadow: '0 0 0 1px rgba(129, 140, 248, 0.5)',
      },
    }),
    valueContainer: (base: any, state: any) => ({
      ...base,
      padding: '0 6px',
      gap: '2px',
      maxHeight: getControlHeight(state.getValue()),
      overflow: 'auto',
      flexWrap: 'wrap',
      scrollbarWidth: 'thin',
      scrollbarColor: '#888 #f1f1f1',
      '&::-webkit-scrollbar': {
        width: '4px',
        height: '4px',
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px',
        '&:hover': {
          background: '#666',
        },
      },
    }),
    multiValue: (base: any, state: any) => ({
      ...base,
      backgroundColor: state.data.color?.bg
        ? `${state.data.color.bg.slice(0, -2)}dd`
        : '#E2E8F0',
      borderRadius: '4px',
      margin: '1px',
      minHeight: '20px',
      maxWidth: '100%',
    }),
    multiValueLabel: (base: any, state: any) => ({
      ...base,
      color: state.data.color?.text || '#1F2937',
      fontSize: '11px',
      padding: '1px 4px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (base: any) => ({
      ...base,
      color: '#EF4444',
      padding: '0 2px',
      ':hover': {
        backgroundColor: 'transparent',
        color: '#DC2626',
      },
      '& svg': {
        width: '12px',
        height: '12px',
        strokeWidth: 2.5,
        stroke: '#EF4444',
        '&:hover': {
          stroke: '#DC2626',
        }
      },
      height: '100%',
      display: 'flex',
      alignItems: 'center',
    }),
    clearIndicator: (base: any) => ({
      ...base,
      padding: '4px',
      color: '#EF4444',
      cursor: 'pointer',
      '& svg': {
        width: '14px',
        height: '14px',
        strokeWidth: 2.5,
        stroke: '#EF4444',
      },
      ':hover': {
        color: '#DC2626',
        '& svg': {
          stroke: '#DC2626',
        }
      },
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      padding: '4px',
    }),
    menuList: (base: any) => ({
      ...base,
      maxHeight: '200px',
      '::-webkit-scrollbar': {
        width: '4px',
        height: '0px',
      },
      '::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: '#555',
      },
    }),
    menu: (base: any) => ({
      ...base,
      zIndex: 50,
    }),
    option: (base: any, state: any) => {
      const hoverStyles = getHoverStyles(state.data.color);
      return {
        ...base,
        backgroundColor: state.isSelected
          ? 'white'
          : state.isFocused
            ? '#FEF9C3'
            : 'white',
        color: state.isSelected
          ? '#1F2937'
          : state.isFocused
            ? '#854D0E'
            : state.data.color?.text || '#1F2937',
        fontSize: '12px',
        padding: '6px 8px',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
        boxShadow: state.isFocused ? '0 0 0 1px rgba(133, 77, 14, 0.1)' : 'none',
        '&:hover': {
          backgroundColor: '#FEF9C3',
          color: '#854D0E',
          boxShadow: '0 0 0 1px rgba(133, 77, 14, 0.1)',
          transform: 'translateX(2px)',
        },
      };
    },
    placeholder: (base: any) => ({
      ...base,
      fontSize: '12px',
    }),
    input: (base: any) => ({
      ...base,
      margin: 0,
      padding: 0,
      fontSize: '12px',
    }),
  };

  const dateRangeStyles = {
    ...customStyles,
    control: (base: any) => ({
      ...base,
      minHeight: '32px',
      maxHeight: '32px',
      background: 'white',
      borderColor: '#E5E7EB',
      borderWidth: '1px',
      borderRadius: '0.5rem',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
      '&:hover': {
        borderColor: '#A5B4FC',
        boxShadow: '0 0 0 1px rgba(165, 180, 252, 0.5)',
      },
      '&:focus-within': {
        borderColor: '#818CF8',
        boxShadow: '0 0 0 1px rgba(129, 140, 248, 0.5)',
      },
    }),
    singleValue: (base: any) => ({
      ...base,
      fontSize: '12px',
    }),
    option: (base: any, state: any) => ({
      ...base,
      fontSize: '12px',
      padding: '4px 8px',
      backgroundColor: state.isSelected
        ? 'white'
        : state.isFocused
          ? '#FEF9C3'
          : 'white',
      color: state.isSelected
        ? '#1F2937'
        : state.isFocused
          ? '#854D0E'
          : '#1F2937',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      '&:hover': {
        backgroundColor: '#FEF9C3',
        color: '#854D0E',
        transform: 'translateX(2px)',
        boxShadow: '0 0 0 1px rgba(133, 77, 14, 0.1)',
      },
    }),
  };

  const CrossIcon = () => (
    <XCircle
      size={14}
      strokeWidth={2.5}
      className="text-red-500 hover:text-red-600"
    />
  );

  return (
    <div className="bg-white rounded-lg shadow-sm p-2 mb-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2">
        <div className="filter-section">
          <label className="block text-xs font-medium text-gray-700 mb-1 flex items-center">
            <Calendar className="w-3 h-3 mr-1" />
            Date Range
          </label>
          <div className="space-y-1">
            <Select
              options={dateRangeOptions}
              value={selectedDateRange}
              onChange={handleDateRangeChange}
              styles={dateRangeStyles}
              className="basic-select"
              classNamePrefix="select"
              placeholder="Select date range..."
              isClearable
              components={{
                ClearIndicator: ({ innerProps }) => (
                  <div {...innerProps} className="px-2 cursor-pointer">
                    <CrossIcon />
                  </div>
                )
              }}
            />
            {showCustomDateRange && (
              <div className="flex gap-1">
                <input
                  type="date"
                  value={dateRange.start}
                  onChange={(e) => setDateRange({ ...dateRange, start: e.target.value })}
                  className="flex-1 px-1 py-1.5 text-xs border border-gray-300 rounded"
                />
                <input
                  type="date"
                  value={dateRange.end}
                  min={dateRange.start}
                  onChange={(e) => setDateRange({ ...dateRange, end: e.target.value })}
                  className="flex-1 px-1 py-1.5 text-xs border border-gray-300 rounded"
                />
              </div>
            )}
          </div>
        </div>

        <div className="filter-section">
          <label className="block text-xs font-medium text-gray-700 mb-1 flex items-center">
            <FilterIcon className="w-3 h-3 mr-1" />
            Topics
          </label>
          <Select
            isMulti
            options={topicOptions}
            value={selectedTopics}
            onChange={(selected) => setSelectedTopics(selected as TopicOption[])}
            styles={customStyles}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Select topics..."
            components={{
              MultiValueRemove: ({ innerProps }) => (
                <div {...innerProps} className="px-1 cursor-pointer">
                  <CrossIcon />
                </div>
              ),
              ClearIndicator: ({ innerProps }) => (
                <div {...innerProps} className="px-2 cursor-pointer">
                  <CrossIcon />
                </div>
              )
            }}
          />
        </div>

        <div className="filter-section lg:col-span-1">
          <label className="block text-xs font-medium text-gray-700 mb-1 flex items-center">
            <Users className="w-3 h-3 mr-1" />
            Users
          </label>
          <div className="flex gap-1">
            <div className="flex-grow">
              <Select
                isMulti
                options={uniqueUsers}
                value={selectedAttendees}
                onChange={(selected) => setSelectedAttendees(selected as AttendeeOption[])}
                styles={customStyles}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Search users..."
                components={{
                  MultiValueRemove: ({ innerProps }) => (
                    <div {...innerProps} className="px-1 cursor-pointer">
                      <CrossIcon />
                    </div>
                  ),
                  ClearIndicator: ({ innerProps }) => (
                    <div {...innerProps} className="px-2 cursor-pointer">
                      <CrossIcon />
                    </div>
                  )
                }}
              />
            </div>
            {hasActiveFilters && (
              <button
                onClick={() => {
                  clearAllFilters();
                  setSelectedDateRange(null);
                  setShowCustomDateRange(false);
                }}
                className="flex items-center px-2 py-1 h-[32px] bg-red-50 text-red-600 rounded hover:bg-red-100 transition-colors duration-200 whitespace-nowrap text-xs"
              >
                <XCircle className="w-3 h-3 mr-1" strokeWidth={2.5} stroke="#EF4444" />
                Clear
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharedFilters;