import { DefaultButton } from '@fluentui/react'
import { NavLink, Link } from 'react-router-dom'

export default function MeetingsEmptyState() {
  return (
    <div>
      <div style={{ marginBottom: '1rem' }}>
        Subscribe to interests and we will schedule meetings with colleagues who shares similar interest
      </div>
      <NavLink to={'/preference'}> 
        <DefaultButton text="Preferences" />
      </NavLink>
    </div>
  )
}
