import { Divider } from '@fluentui/react-components'
import { CalendarLtr24Regular, ArrowTrendingSparkle24Regular } from '@fluentui/react-icons'
import { Link } from 'react-router-dom'

export default function Reports() {
  return (
    <div
      style={{
        fontSize: '15px',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '0.5rem',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Link to={'/attendanceReports'} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div style={{ display: 'flex', gap: '10px', alignItems: 'center', marginLeft: '.5rem' }}>
          <span>
            <CalendarLtr24Regular />
          </span>
          <span className="ms-fontWeight-semibold">
            Meetings
            <span className="ms-fontWeight-regular ms-fontSize-12	" style={{ color: '#616161' }}>
              <div>Find details about past WaterCooler breaks!</div>
            </span>
          </span>
        </div>
      </Link>
      <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
      <Link to={'/trending'} style={{ textDecoration: 'none', color: 'inherit' }}>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            marginLeft: '.5rem',
          }}
        >
          <span>
            <ArrowTrendingSparkle24Regular />
          </span>
          <span className="ms-fontWeight-semibold">
            Trending Interests
            <span className="ms-fontWeight-regular ms-fontSize-12	" style={{ color: '#616161' }}>
              <div>See which interests are most popular in your organization</div>
            </span>
          </span>
        </div>
      </Link>
    </div>
  )
}
