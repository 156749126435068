import { Field, Button } from '@fluentui/react-components'
import { TimePicker, TimePickerProps, formatDateToTimeString } from '@fluentui/react-timepicker-compat'
import { useEffect, useState } from 'react'
import { useAppStateStore } from '../../store'
import moment from 'moment'
import { apiGet, apiPost } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'
import TimezonePicker, { SelectTimezoneProps } from '../../components/TimeZonePicker'

export default function WorkingHours() {
  const [availableFromError, setAvailableFromError] = useState<string>('')
  const [availableUntilError, setAvailableUntilError] = useState<string>('')
  const [timezoneError, setTimezoneError] = useState<string>('')
  
  const appState = useAppStateStore()

  const dateFromHour = (hour: number) => {
    return new Date(moment().set({ hour: hour, minute: 0 }).format('YYYY-MM-DD HH:mm'))
  }

  type InterestModel = {
    id: string
    name: string
  }

  type UserPreferenceModel = {
    workingHours: {
      from: number
      until: number
      timeZone: string
    }
    nextMeetingInDays: number
    interests: InterestModel[]
  }
  
  const timezoneStyles = {
    timezoneContainer: {
      width: 300,
      minHieght: 40,
      color:'black',
      dir:"ltl",
      textAlign: "left", 
      marginLeft:0,
      position: "relative",
      justifyContent: 'center', alignItems: 'center', 
    },
    
    timezoneContainerLabel: {
      color:'white',
    },
    timezoneContainerSelect: {
      color:'black',
    }
  }

  const timezoneInfo = {
    id: "Timezone",
    name: "WaterCooler Timezone Picker",
    label: "Select Timezone",
    isSearchable: true
  }

  useEffect(() => {
    getUsersTimezoneFromDB()
  }, [])

  const getUsersTimezoneFromDB = async () => {
    try {
      const userPreferences = await apiGet<UserPreferenceModel>('settings', appState.identity.accessToken)
      appState.setTimeZone(userPreferences.workingHours.timeZone);
    } catch (err: any) {
      logger.debug(err)
    }
  }
  const setWorkingHours = async (from: number, until: number, timezone: string) => {
    try {
      await apiPost('settings/set-working-hours', appState.identity.accessToken, {
        availableFrom: from,
        availableUntil: until,
        timezone: timezone
      })

      appState.setWorkingHours(from, until, timezone)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  const onAvailableFromChange: TimePickerProps['onTimeChange'] = (_ev, data) => {
    const fromHour = moment(data.selectedTime).hour()
    if (appState.profile.workingHours.until <= fromHour) {
      setAvailableFromError('From time should be lesser than To time')
      return
    }

    setAvailableFromError('')
    setWorkingHours(fromHour, appState.profile.workingHours.until, appState.profile.workingHours.timezone)
  }

  const onAvailableUntilChange: TimePickerProps['onTimeChange'] = (_ev, data) => {
    const untilHour = moment(data.selectedTime).hour()
    if (untilHour <= appState.profile.workingHours.from) {
      setAvailableUntilError('To time should be greater than From time')
      return
    }

    setAvailableUntilError('')
    setWorkingHours(appState.profile.workingHours.from, untilHour, appState.profile.workingHours.timezone)
  }

  const setSelectedTimezoneValue: SelectTimezoneProps['onChange'] = (selectedDplTimezoneLabel, selectedDplTimezoneValue) => {
    if (selectedDplTimezoneValue == "" || selectedDplTimezoneValue == undefined || selectedDplTimezoneValue == null) {
      setTimezoneError('Please Select the Timezone')
      return
    }
    else {
      appState.setTimeZone(selectedDplTimezoneValue);
    }
    setTimezoneError('')
    setWorkingHours(appState.profile.workingHours.from, appState.profile.workingHours.until, selectedDplTimezoneValue)
  }

  return (
    <>
      <div style={{ display: 'inline-flex', gap: '1rem' }} className="ms-Grid">
      <div className="ms-Grid-row">
        <div className="ms-Grid-col">
          <Field label="From" validationMessage={availableFromError}>
            <TimePicker
              increment={60}
              hourCycle="h11"
              freeform={false}
              defaultSelectedTime={dateFromHour(appState.profile.workingHours.from)}
              defaultValue={formatDateToTimeString(dateFromHour(appState.profile.workingHours.from))}
              onTimeChange={onAvailableFromChange}
            />
          </Field>
        </div>
        <div className="ms-Grid-col">
        <Field label="To" validationMessage={availableUntilError}>
          <TimePicker
            increment={60}
            hourCycle="h11"
            freeform={false}
            defaultSelectedTime={dateFromHour(appState.profile.workingHours.until)}
            defaultValue={formatDateToTimeString(dateFromHour(appState.profile.workingHours.until))}
            onTimeChange={onAvailableUntilChange}
          />
        </Field>
        </div>
        <div className="ms-Grid-col">
          <Field label={"TimeZone"} validationMessage={timezoneError}>
              <TimezonePicker
                timezoneInfo={timezoneInfo} timezoneStyles={timezoneStyles}
                onChange={( label, value ) => setSelectedTimezoneValue(label, value)}
                selectedTimeZone={appState.profile.workingHours.timezone ? appState.profile.workingHours.timezone : appState.profile.usersCurrentTimezone}
              />
          </Field>
        </div>
      </div>
     </div>
    </>
  )
}
