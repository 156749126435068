import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FluentProvider, ToggleButton, teamsLightTheme, Option, Dropdown } from '@fluentui/react-components'
import { LayoutGrid, BarChart3 } from 'lucide-react';
import { IMeetingInfo } from './types';
import { generateMeetingData } from './utils/dataGenerator';
import CardView from './components/Reports/CardView';
import GraphView from './components/Reports/GraphView';
import GlobalDownload from './components/Reports/GlobalDownload';
import SharedFilters from './components/Reports/filters/SharedFilters';
import Header from './components/header';
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb';

function AttendanceReportTestUI() {
  const [view, setView] = useState<'cards' | 'graph'>('cards');
  const [meetings, setMeetings] = useState(() => generateMeetingData(25)); // Increased from 20 to 40 meetings
  const [selectedTopics, setSelectedTopics] = useState<any[]>([]);
  const [selectedAttendees, setSelectedAttendees] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState({ start: '', end: '' });

  const colors = [
    { bg: '#EEF2FF', text: '#4F46E5', chart: 'rgba(79, 70, 229, 0.8)' },
    { bg: '#F0F9FF', text: '#0369A1', chart: 'rgba(3, 105, 161, 0.8)' },
    { bg: '#FDF4FF', text: '#A21CAF', chart: 'rgba(162, 28, 175, 0.8)' },
    { bg: '#FFF1F2', text: '#BE123C', chart: 'rgba(190, 18, 60, 0.8)' },
    { bg: '#FEF3C7', text: '#92400E', chart: 'rgba(146, 64, 14, 0.8)' },
    { bg: '#F5F3FF', text: '#5B21B6', chart: 'rgba(91, 33, 182, 0.8)' },
    { bg: '#FFF7ED', text: '#9A3412', chart: 'rgba(154, 52, 18, 0.8)' },
    { bg: '#F0FDF4', text: '#166534', chart: 'rgba(22, 101, 52, 0.8)' }
  ];

  const topicOptions = Array.from(new Set(meetings.map(m => m.interestName)))
    .map((topic, index) => ({
      value: topic,
      label: topic,
      color: colors[index % colors.length]
    }));

  const attendeeOptions = meetings.reduce((acc, meeting) => {
    const topic = meeting.interestName;
    const color = colors[topicOptions.findIndex(t => t.value === topic) % colors.length];

    meeting.attendees.forEach(attendee => {
      const key = `${attendee.attendeeEmail}-${topic}`;
      if (!acc[topic]) {
        acc[topic] = {
          label: topic,
          options: []
        };
      }
      if (!acc[topic].options.some((opt: any) => opt.value === key)) {
        acc[topic].options.push({
          value: key,
          label: `${attendee.attendeeName} (${attendee.attendeeEmail})`,
          email: attendee.attendeeEmail,
          topic,
          color
        });
      }
    });
    return acc;
  }, {} as any);

  const hasActiveFilters: boolean = selectedTopics.length > 0 || selectedAttendees.length > 0 || dateRange.start !== '' || dateRange.end !== '';

  const clearAllFilters = () => {
    setSelectedTopics([]);
    setSelectedAttendees([]);
    setDateRange({ start: '', end: '' });
  };

  const filteredMeetings = meetings.filter(meeting => {
    if (selectedTopics.length && !selectedTopics.some(t => t.value === meeting.interestName)) {
      return false;
    }
    if (selectedAttendees.length && !meeting.attendees.some(a => selectedAttendees.some(sa => sa.email === a.attendeeEmail))) {
      return false;
    }
    if (dateRange.start) {
      const meetingDate = new Date(meeting.meetingStartDateTime);
      const startDate = new Date(dateRange.start);
      if (meetingDate < startDate) return false;
    }
    if (dateRange.end) {
      const meetingDate = new Date(meeting.meetingStartDateTime);
      const endDate = new Date(dateRange.end);
      if (meetingDate > endDate) return false;
    }
    return true;
  });

  return (
    <FluentProvider theme={teamsLightTheme}>
      <div className="min-h-screen p-4 sm:p-6 lg:p-8">
        <div className="app-container max-w-9xl mx-auto rounded-lg shadow-lg p-6">
          {/* <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
                      Attendance Report
                  </h1> */}
          <div style={{ backgroundColor: '#EFF5F9' }}>
            <Header />

            <div style={{ marginLeft: '0rem', marginBottom: '1rem', marginTop: '-3rem' }}>
              <Breadcrumb
                items={[
                  { text: 'Home', key: 'home', href: '#/tab' },
                  { text: 'Preferences', key: 'preferences', href: '#/preference' },
                  { text: 'Meetings Report', key: 'meetingsReport', isCurrentItem: true },
                ]}
              />
            </div>
          </div>

          <div className="flex justify-between items-center mb-6">
            <div className="flex space-x-2 bg-gray-100 p-1 rounded-lg">
              <button
                onClick={() => setView('cards')}
                className={`flex items-center px-4 py-2 rounded-md ${view === 'cards'
                  ? 'bg-white text-blue-600 shadow'
                  : 'text-gray-600 hover:text-gray-900'
                  }`}
              >
                <LayoutGrid className="w-5 h-5 mr-2" />
                Grid View
              </button>
              <button
                onClick={() => setView('graph')}
                className={`flex items-center px-4 py-2 rounded-md ${view === 'graph'
                  ? 'bg-white text-blue-600 shadow'
                  : 'text-gray-600 hover:text-gray-900'
                  }`}
              >
                <BarChart3 className="w-5 h-5 mr-2" />
                Graph View
              </button>
            </div>
            <GlobalDownload meetings={filteredMeetings} />
          </div>

          <SharedFilters
            topicOptions={topicOptions}
            selectedTopics={selectedTopics}
            setSelectedTopics={setSelectedTopics}
            attendeeOptions={Object.values(attendeeOptions)}
            selectedAttendees={selectedAttendees}
            setSelectedAttendees={setSelectedAttendees}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasActiveFilters={hasActiveFilters}
            clearAllFilters={clearAllFilters}
          />

          <AnimatePresence mode="wait">
            {view === 'cards' ? (
              <motion.div
                key="cards"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                <CardView meetings={filteredMeetings} />
              </motion.div>
            ) : (
              <motion.div
                key="graph"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.2 }}
              >
                <GraphView meetings={filteredMeetings} />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </FluentProvider>
  );
}
export default AttendanceReportTestUI;