import { Card, CardFooter, CardHeader, makeStyles } from '@fluentui/react-components'
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button'
import { Link } from 'react-router-dom'
import { useAppStateStore } from '../../store'
export interface IGreetingProps {
  displayName: string | undefined
}

const useStyles = makeStyles({
  card: {
    width: '311px',
    backgroundColor: 'white',
    height: '600px',
  },
})

export default function WelcomeUser() {
  const appState = useAppStateStore()
  const styles = useStyles()

  return (
    <div>
      <Card
        className={styles.card}
        style={{ padding: '64px 36px 24px 36px', gap: '24px', border: '1px solid #D6D6D6', background: 'white' }}
      >
        <CardHeader
          header={
            <span className="ms-fontSize-24 ms-fontWeight-semibold" style={{ lineHeight: '28px' }}>
              Hello, {appState.identity.name}. Welcome to Watercooler! We're glad you're here.
            </span>
          }
        />

        <span style={{ color: '#242424', paddingBottom: '54px' }} className="ms-fontSize-14">
          Let's turn workplace connections into fantastic moments by the Watercooler!
        </span>

        <span className=" ms-fontWeight-bold" style={{ color: '#242424' }}>
          Share your interests to connect with colleagues who have similar passions and build new friendships!
        </span>

        <CardFooter>
          <Link to={'#/preferences'}>
            <PrimaryButton style={{ fontSize: '14px', lineHeight: '20px' }}>Preferences</PrimaryButton>
          </Link>
        </CardFooter>
      </Card>
    </div>
  )
}
