import { Divider } from '@fluentui/react-components'
import InterestPreference from './InterestPreference'
import { Interest } from '../../store'
import _ from 'lodash'

export interface InterestPreferencesProps {
  interests: Interest[]
  onChangePreference: (id: string, isUserSubscribed: boolean) => Promise<void>
}

export default function InterestPreferences(props: InterestPreferencesProps) {
  return (
    <>
      {props.interests &&
        _.sortBy(props.interests, [(interest) => !interest.isUserSubscribed, (interest) => interest.name]).map(
          (interest: Interest, index: number) => (
            <div key={interest.id}>
              <InterestPreference
                id={interest.id}
                name={interest.name}
                isUserSubscribed={interest.isUserSubscribed}
                onChangePreference={props.onChangePreference}
              ></InterestPreference>
              {props.interests.length !== index + 1 && (
                <Divider style={{ padding: '0.4rem' }} appearance="subtle"></Divider>
              )}

              {index + 1 === props.interests.filter((x, _) => x.isUserSubscribed).length && <p></p>}
            </div>
          )
        )}
    </>
  )
}
