export const excelStyles = {
  title: {
    font: { bold: true, size: 14, color: { rgb: "4F46E5" } },
    alignment: { horizontal: "center" },
    fill: { fgColor: { rgb: "EEF2FF" } }
  },
  header: {
    fill: { 
      fgColor: { rgb: '4F46E5' },
      patternType: 'solid'
    },
    font: { 
      color: { rgb: 'FFFFFF' }, 
      bold: true,
      size: 11
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      horizontal: 'center',
      vertical: 'center'
    }
  },
  cell: {
    font: { 
      color: { rgb: '000000' },
      size: 10
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      vertical: 'center',
      horizontal: 'left',
    }
  },
  attended: {
    font: { 
      color: { rgb: '166534' },
      size: 10
    },
    fill: { 
      fgColor: { rgb: 'DCFCE7' },
      patternType: 'solid'
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      vertical: 'center',
      horizontal: 'left',
    }
  },
  notAttended: {
    font: { 
      color: { rgb: '991B1B' },
      size: 10
    },
    fill: { 
      fgColor: { rgb: 'FEE2E2' },
      patternType: 'solid'
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      vertical: 'center',
      horizontal: 'left',
    }
  },
  accepted: {
    font: { 
      color: { rgb: '854D0E' },
      size: 10
    },
    fill: { 
      fgColor: { rgb: 'FEF9C3' },
      patternType: 'solid'
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      vertical: 'center',
      horizontal: 'left',
    }
  },
  notAccepted: {
    font: { 
      color: { rgb: '991B1B' },
      size: 10
    },
    fill: { 
      fgColor: { rgb: 'FEE2E2' },
      patternType: 'solid'
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      vertical: 'center',
      horizontal: 'left',
    }
  },
  highRate: {
    font: { 
      color: { rgb: '166534' },
      size: 10
    },
    fill: { 
      fgColor: { rgb: 'DCFCE7' },
      patternType: 'solid'
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      vertical: 'center',
      horizontal: 'left',
    }
  },
  mediumRate: {
    font: { 
      color: { rgb: '854D0E' },
      size: 10
    },
    fill: { 
      fgColor: { rgb: 'FEF9C3' },
      patternType: 'solid'
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      vertical: 'center',
      horizontal: 'left',
    }
  },
  lowRate: {
    font: { 
      color: { rgb: '991B1B' },
      size: 10
    },
    fill: { 
      fgColor: { rgb: 'FEE2E2' },
      patternType: 'solid'
    },
    border: {
      top: { style: 'thin', color: { rgb: '000000' } },
      bottom: { style: 'thin', color: { rgb: '000000' } },
      left: { style: 'thin', color: { rgb: '000000' } },
      right: { style: 'thin', color: { rgb: '000000' } }
    },
    alignment: {
      vertical: 'center',
      horizontal: 'left',
    }
  }
} as const;

export type ExcelStyleKey = keyof typeof excelStyles;

export const COLORS = {
  primary: '#4F46E5',
  success: '#047857',
  error: '#DC2626',
  warning: '#D97706',
  light: {
    primary: '#EEF2FF',
    success: '#ECFDF5',
    error: '#FEF2F2',
    warning: '#FFFBEB',
  }
};

export const pdfStyles = {
  header: {
    fontSize: 20,
    color: '#4F46E5'
  },
  subHeader: {
    fontSize: 16,
    color: '#4B5563'
  },
  table: {
    headerBg: '#4F46E5',
    headerTextColor: '#FFFFFF',
    rowOddBg: '#F3F4F6'
  }
};