import React, { useState } from "react"
import ReactDOM from "react-dom"
import { SelectTimezone } from "reactjs-timezone-select";

interface TimeZone {
  name: string;
  alternativeName: string;
  group: string[];
  continentCode: string;
  continentName: string;
  countryName: string;
  countryCode: string;
  mainCities: string[];
  rawOffsetInMinutes: number;
  abbreviation: string;
  rawFormat: string;
  currentTimeOffsetInMinutes: number;
  currentTimeFormat: string;
}

export interface SelectTimezoneProps {
  label?: string | React.ReactNode;
  name?: string;
  value?: string;
  onChange: ( label: string, value: string ) => void;
  isDisabled?: boolean;
  isSearchable?: boolean;
  defaultToSystemTimezone?: boolean;
  containerStyles?: object;
  labelStyles?: object;
  selectStyles?: object;
  optionLabelFormat?: (timezone: TimeZone) => string;
  [key: string]: any;
}

export default function TimeZonePicker(props: SelectTimezoneProps) {
  const {timezoneInfo, timezoneStyles, selectedTimeZone} = props;
  
  const customTimezoneStyles = {
    control: (provided: Record<string, unknown>, state: any) => ({
      ...provided,
      minHeight: 32,
      boxShadow: 'none',
      border: "0.2px solid rgb(201 200 199)", 
      minWidth: "300px", borderRadius: "4px", height: 32, 
      borderBottom: "1px solid #616161",
      '&:hover': {
        color: '#60B3D1'
      },
      //border: state.isFocused ? '1.5px solid #60B3D1' : '1.5px solid #cbd5e1'
    }),
    option: (styles: any, state: any) => ({
      ...styles,
      color: state.isSelected ? '#FFF' : styles.color,
      backgroundColor: state.isSelected ? '#60B3D1' : styles.color,
      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
      '&:hover': {
      color: '#FFF',
      backgroundColor: '#60B3D1',
    }
    }),
    singleValue: (provided:any) => ({
      ...provided,
      color: "black",
      top: 8,
    }),
    dropdownIndicator: (styles: any, state: any) => ({ 
      ...styles, 
      bottom:2,
      transform: state.selectProps.menuIsOpen && "rotate(180deg)",
      position: "relative"
    }),
    indicatorSeparator: (styles: any,state:any) => ({
      ...styles, 
      position: "relative",
      bottom:3,
    }),
    input: (styles: any, state: any, base: any) => ({
      ...base,
      position: "relative",
      paddingBottom: 3,
      'input:focus': {
        boxShadow: 'none',
        border: '1px solid #60B3D1',
      }
    }),
    menuPortal: (base: any, provided: React.CSSProperties) => ({ 
      ...base, zIndex: 9999, menuPosition : "fixed",  
    }),
    menu: (provided: React.CSSProperties) => ({
      ...provided,
      top: -5,
      position: 'absolute',
      zIndex: 999999,
      fontSize: '13px',
      maxHeight: '350px', // Set a max height for the menu
      //overflowY: 'scroll', // Enable vertical scrolling
    })
  };
    return (
      <>
        <SelectTimezone
          id= {timezoneInfo.id}
          name={timezoneInfo.name}
          isSearchable= {timezoneInfo.isSearchable}
          value={selectedTimeZone}
          onChange={(ev) => props.onChange(timezoneInfo.label, ev.value)}
          defaultToSystemTimezone = {true}
          containerStyles={timezoneStyles.timezoneContainer}
          labelStyles= {timezoneStyles.timezoneContainerLabel}
          selectStyles={customTimezoneStyles}
          aria-sort="ascending"
          menuPortalTarget={document.body}
          optionLabelFormat= {(timezone) => 
          `${timezone.name} (${timezone.abbreviation})`
          //`${timezone.name} (${timezone.abbreviation} ${timezone.currentTimeFormat} - ${timezone.countryName})`
          }
        />
      </>
    );
}
