import React, { useState } from 'react';
import { IMeetingInfo } from '../../types';
import AvatarGroup from './AvatarGroup';
import Pagination from './Pagination';

interface CardViewProps {
  meetings: IMeetingInfo[];
}

const ITEMS_PER_PAGE = 12;

const CardView: React.FC<CardViewProps> = ({ meetings }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(meetings.length / ITEMS_PER_PAGE);

  const indexOfLastMeeting = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstMeeting = indexOfLastMeeting - ITEMS_PER_PAGE;
  const currentMeetings = meetings.slice(indexOfFirstMeeting, indexOfLastMeeting);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="space-y-6">
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-6 p-1">
        {currentMeetings.map((meeting, index) => (
          <div key={meeting.meetingId} className="transform-gpu">
            <AvatarGroup
              meetingInfo={meeting}
              colorIndex={index}
            />
          </div>
        ))}
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default CardView;