export default function EmptyPage() {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        marginTop: '10rem',
        justifyContent: 'center',
      }}
    >
      <span style={{ fontSize: '2rem' }}>No data Available</span>
    </div>
  )
}
