import { produce } from 'immer'
import { create } from 'zustand'

export type AdminSettings = {
  meetingAttendeeCount: {
    min: number
    max: number
  }
}

export type Identity = {
  id: string
  name: string
  isFirstLogin: boolean
  isAdmin: boolean
  accessToken: string
}

export type Company = {
  id: string
  isNewCompany: boolean
}

export type Profile = {
  workingHours: {
    from: number
    until: number
    timezone: string
  }
  usersCurrentTimezone: string
  meetingFrequency: number
  profilePicUrl: string
}

export type Interest = {
  id: string
  name: string
  isUserSubscribed: boolean
}

type UserPreferenceInput = {
  workingHours: {
    from: number
    until: number
    timeZone: string
  }
  nextMeetingInDays: number
  interests: { id: string }[]
}

type State = {
  identity: Identity
  company: Company
  profile: Profile
  adminSettings: AdminSettings
  interests: Map<string, Interest>
  selectedInterestsCount: number
}

type Action = {
  setUserOnboardResult: (user: { id: string; name: string; isFirstLogin: boolean; isAdmin: boolean }) => void
  setAccessToken: (accessToken: string) => void
  setCompany: (id: string, isNewCompany: boolean) => void
  setMeetingAttendeeCount: (min: number, max: number) => void
  setMeetingFrequency: (meetingFrequency: number) => void
  setWorkingHours: (from: number, until: number, timezone: string) => void
  setTimeZone: (timezone: string) => void
  setUserPreference: (userPreference: UserPreferenceInput) => void
  setProfilePicUrl: (accessToken: string) => void
  addInterest: (id: string, name: string) => void
  updateInterest: (id: string, isUserSubscribed: boolean) => void
}

export const useAppStateStore = create<State & Action>()((set) => ({
  identity: {
    id: '',
    name: '',
    isFirstLogin: false,
    isAdmin: false,
    accessToken: '',
  },
  company: {
    id: '',
    isNewCompany: false,
  },
  profile: {
    workingHours: {
      from: 9,
      until: 17,
      timezone: (Intl.DateTimeFormat().resolvedOptions().timeZone).toString()
    },
    usersCurrentTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    meetingFrequency: 1,
    profilePicUrl: '',
  },
  isNewCompany: false,
  interests: new Map<string, Interest>(),
  selectedInterestsCount: 0,
  adminSettings: { meetingAttendeeCount: { min: 2, max: 8 } },
  setUserOnboardResult: (user: { id: string; name: string; isFirstLogin: boolean; isAdmin: boolean }) => {
    set(
      produce((state: State) => {
        state.identity.id = user.id
        state.identity.name = user.name
        state.identity.isFirstLogin = user.isFirstLogin
        state.identity.isAdmin = user.isAdmin
      })
    )
  },
  setAccessToken: (accessToken: string) => {
    set(
      produce((state: State) => {
        state.identity.accessToken = accessToken
      })
    )
  },
  setCompany: (id: string, isNewCompany: boolean) => {
    set(
      produce((state: State) => {
        state.company.id = id
        state.company.isNewCompany = isNewCompany
      })
    )
  },
  setMeetingAttendeeCount: (min: number, max: number) => {
    set(
      produce((state: State) => {
        state.adminSettings.meetingAttendeeCount.min = min
        state.adminSettings.meetingAttendeeCount.max = max
      })
    )
  },
  setMeetingFrequency: (meetingFrequency: number) => {
    set(
      produce((state: State) => {
        state.profile.meetingFrequency = meetingFrequency
      })
    )
  },
  setWorkingHours: (from: number, until: number, timezone: string) => {
    set(
      produce((state: State) => {
        state.profile.workingHours.from = from
        state.profile.workingHours.until = until
        state.profile.workingHours.timezone = timezone
      })
    )
  },
  setTimeZone: (timezone: string) => {
    set(
      produce((state: State) => {
        state.profile.workingHours.timezone = timezone
      })
    )
  },
  setUserPreference: (userPreference: UserPreferenceInput) => {
    set(
      produce((state: State) => {
        state.profile.workingHours.from = userPreference.workingHours.from
        state.profile.workingHours.until = userPreference.workingHours.until
        state.profile.workingHours.timezone = userPreference.workingHours.timeZone
        state.profile.meetingFrequency = userPreference.nextMeetingInDays

        state.selectedInterestsCount = 0
        userPreference.interests.forEach((interest) => {
          if (state.interests.has(interest.id)) {
            state.interests.get(interest.id)!.isUserSubscribed = true
            state.selectedInterestsCount = state.selectedInterestsCount + 1
          }
        })
      })
    )
  },
  setProfilePicUrl: (profilePicUrl: string) => {
    set(
      produce((state: State) => {
        state.profile.profilePicUrl = profilePicUrl
      })
    )
  },
  addInterest: (id: string, name: string) => {
    set(
      produce((state: State) => {
        state.interests.set(id, { id: id, name: name, isUserSubscribed: false })
      })
    )
  },

  updateInterest: (id: string, isUserSubscribed: boolean) => {
    set(
      produce((state: State) => {
        if (state.interests.has(id)) {
          state.interests.get(id)!.isUserSubscribed = isUserSubscribed
        }
      })
    )

    set((state: State) => ({ selectedInterestsCount: state.selectedInterestsCount + (isUserSubscribed ? 1 : -1) }))
  },
}))
