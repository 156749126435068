import { IMeetingInfo } from '../types';
import { generateMeetingStats, generateAttendeeRow } from './exportHelpers';

export const generateCSVContent = (meetingInfo: IMeetingInfo): string => {
  const stats = generateMeetingStats(meetingInfo);
  let csvContent = '\ufeff'; // Add BOM for Excel

  // Meeting Summary Section
  csvContent += 'Meeting Summary\n\n';
  
  // Summary Headers
  const summaryHeaders = [
    'Topic',
    'Total Participants',
    'Attended',
    'Not Attended',
    'Accepted',
    'Attendance Rate',
    'Acceptance Rate',
    'Avg Attendance',
    'Start Time',
    'End Time'
  ];
  csvContent += summaryHeaders.join(',') + '\n';

  // Summary Data
  const summaryData = [
    `"${stats.topic}"`,
    stats.totalParticipants,
    `"✓ ${stats.attended.value}"`,
    `"✗ ${stats.notAttended.value}"`,
    `"★ ${stats.accepted.value}"`,
    `"${stats.attendanceRate.value}"`,
    `"${stats.acceptanceRate.value}"`,
    `"${stats.avgAttendanceTime}"`,
    `"${stats.startTime}"`,
    `"${stats.endTime}"`
  ];
  csvContent += summaryData.join(',') + '\n\n';

  // Add a few blank lines for separation
  csvContent += '\n\n\n';

  // Attendee Details Section
  csvContent += 'Attendee Details\n\n';

  // Attendee Headers
  const attendeeHeaders = [
    'Topic',
    'Name',
    'Email',
    'Status',
    'Accepted',
    'Join Time',
    'Leave Time',
    'Duration',
    'Join Date',
    'Leave Date'
  ];
  csvContent += attendeeHeaders.join(',') + '\n';

  // Attendee Data
  meetingInfo.attendees.forEach(attendee => {
    const row = generateAttendeeRow(attendee, meetingInfo);
    const rowData = [
      `"${row.topic}"`,
      `"${row.name}"`,
      `"${row.email}"`,
      `"${row.status.value === 'Attended' ? '✓ ' : '✗ '}${row.status.value}"`,
      `"${row.accepted.value === 'Yes' ? '★ ' : '☆ '}${row.accepted.value}"`,
      `"${row.joinTime}"`,
      `"${row.leaveTime}"`,
      `"${row.duration}"`,
      `"${row.joinDate}"`,
      `"${row.leaveDate}"`
    ];
    csvContent += rowData.join(',') + '\n';
  });

  // Add legend at the bottom
  csvContent += '\n\nLegend:\n';
  csvContent += '✓ = Attended\n';
  csvContent += '✗ = Did Not Attend\n';
  csvContent += '★ = Accepted\n';
  csvContent += '☆ = Not Accepted\n';

  return csvContent;
};