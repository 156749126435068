import Header from '../../components/header'
import { FluentProvider, ToggleButton, makeStyles, shorthands, teamsLightTheme } from '@fluentui/react-components'
import { useAppStateStore } from '../../store'
import { useEffect, useState } from 'react'
import React from 'react'
import { Breadcrumb } from '@fluentui/react/lib/Breadcrumb'
import type { SelectTabData, SelectTabEvent, TabValue } from '@fluentui/react-components'
import { DonutChart, IChartProps, IChartDataPoint, DataVizPalette, getColorFromToken } from '@fluentui/react-charting'
import { DocumentCard } from '@fluentui/react/lib/DocumentCard'
import EmptyPage from './EmptyPage'
import { apiGet } from '../../services/fetch/apiClient'
import { logger } from '../../components/App'
import { Filter24Regular } from '@fluentui/react-icons'

const useStyles = makeStyles({
  root: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    ...shorthands.padding('10px', '10px'),
    rowGap: '10px',
  },
})
const DonutChartComponent: React.FC<{ data: IChartDataPoint[] }> = ({ data }) => {
  const chartData: IChartProps = {
    chartTitle: 'Donut chart dynamic example',
    chartData: data,
  }
  return (
    <DonutChart
      height={500}
      width={600}
      data={chartData}
      hideLabels={false}
      innerRadius={105}
      showLabelsInPercent={true}
    />
  )
}
export default function TrendingTopics() {
  const styles = useStyles()
  const [selectedValue, setSelectedValue] = React.useState<TabValue>('7days')
  const [EventActive, SetEventActive] = React.useState(1)
  const [trendingTopics, setTrendingTopics] = useState<any[]>([])
  const [activeButton, setActiveButton] = useState(1)

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value)
  }

  const handleClick = (index: number) => {
    setActiveButton(index)
  }

  useEffect(() => {
    if (activeButton === 1) {
      daysIndex(0)
    } else if (activeButton === 2) {
      daysIndex(1)
    } else if (activeButton === 3) {
      daysIndex(2)
    }
  }, [activeButton])

  const appState = useAppStateStore()
  useEffect(() => {
    getTrendingTopics()
    handleClick(1)
  }, [])

  const getTrendingTopics = async () => {
    try {
      const trendingTopicsResult = await apiGet<any[]>('dashboard/top-topics', appState.identity.accessToken)
      setTrendingTopics(trendingTopicsResult)
    } catch (err: any) {
      logger.debug(err)
    }
  }

  const [dynamicData, setDynamicData] = useState<IChartDataPoint[]>([])

  const daysIndex = async (index: number) => {
    if (trendingTopics[index] && trendingTopics[index].events) {
      if (trendingTopics[index].events.length > 0) {
        SetEventActive(1)
        const updatedData = trendingTopics[index].events.map((event: any, index: any) => ({
          legend: event.topic,
          data: event.eventCount,
          color: getColorFromToken(DataVizPalette[`color${index + 1}` as keyof typeof DataVizPalette]),
        }))

        setDynamicData(updatedData)
      } else {
        SetEventActive(0)
      }
    }
  }

  return (
    <FluentProvider theme={teamsLightTheme}>
      <div style={{ backgroundColor: '#EFF5F9' }}>
        <Header />

        <div style={{ marginLeft: '3rem' }}>
          <Breadcrumb
            items={[
              { text: 'Home', key: 'home', href: '#/tab' },
              { text: 'Preferences', key: 'preferences', href: '#/preference' },
              { text: 'Trending Interests', key: 'trendingInterests', isCurrentItem: true },
            ]}
          />
        </div>

        <div
          style={{
            marginLeft: '2.5rem',
            marginRight: '4rem',
          }}
        >
          {' '}
          <DocumentCard
            style={{
              maxWidth: '100%',
              width: '100%',
              height: '3.5rem',
              padding: '1rem',
              border: '1px solid #F9F9F9',
              borderRadius: '0.5rem',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'row',
              gap: '1rem',
              boxShadow: '0 3px 3px 0 rgba(0, 0, 0, .13)',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span>
                <Filter24Regular />
              </span>
              <span style={{ fontWeight: '500' }}>Filter By :</span>
            </div>
            <div>
              <ToggleButton
                checked={activeButton === 1}
                onClick={() => {
                  handleClick(1)
                }}
                style={{
                  backgroundColor: activeButton === 1 ? '#1b75d0' : 'white',
                  color: activeButton === 1 ? 'white' : 'black',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              >
                7 Days
              </ToggleButton>
              <ToggleButton
                checked={activeButton === 2}
                onClick={() => {
                  handleClick(2)
                }}
                style={{
                  backgroundColor: activeButton === 2 ? '#1b75d0' : 'white',
                  color: activeButton === 2 ? 'white' : 'black',
                  borderRadius: 0,
                  borderLeftWidth: 0,
                  borderRightWidth: 0,
                }}
              >
                30 Days
              </ToggleButton>
              <ToggleButton
                checked={activeButton === 3}
                onClick={() => {
                  handleClick(3)
                }}
                style={{
                  backgroundColor: activeButton === 3 ? '#1b75d0' : 'white',
                  color: activeButton === 3 ? 'white' : 'black',
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                }}
              >
                60 Days
              </ToggleButton>
            </div>
          </DocumentCard>
          <div>
            {activeButton === 1 && (
              <>
                {EventActive == 1 && <DonutChartComponent data={dynamicData} />}
                {EventActive == 0 && <EmptyPage />}{' '}
              </>
            )}
          </div>
          <div>
            {activeButton === 2 && (
              <>
                {EventActive == 1 && <DonutChartComponent data={dynamicData} />}
                {EventActive == 0 && <EmptyPage />}{' '}
              </>
            )}
          </div>
          <div>
            {activeButton === 3 && (
              <>
                {EventActive == 1 && <DonutChartComponent data={dynamicData} />}
                {EventActive == 0 && <EmptyPage />}{' '}
              </>
            )}
          </div>
        </div>
      </div>
    </FluentProvider>
  )
}
